import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  display: flex;
  margin: 0;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
`

const Container = ({ backgroundColor, ...props }) => {
  return <Wrapper backgroundColor={backgroundColor}>{props.children}</Wrapper>
}

export default Container
